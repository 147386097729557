const BUDGET_DATA = [
  {
    id: 1,
    projectMainName: "CLM 2024",
    localDev: 41000000,
    projectCode: 960075,
    note: "38MB - DEV / 3.06MB - UXUI",
    status: "",
    dev: 90.5,
    qa: 43,
  },
  {
    id: 2,
    projectMainName: "SSPOG",
    localDev: 2000000,
    projectCode: "960049",
    note: "",
    status: "",
    dev: 10,
    qa: 0,
  },
  {
    id: 3,
    projectMainName: "Amaze",
    localDev: 3600000,
    projectCode: "840026",
    note: "Budget from O2O",
    status: "",
    dev: 14,
    qa: 3,
  },
  // {
  //   id: 4,
  //   projectMainName: "LMP",
  //   localDev: 10000000,
  //   projectCode: "",
  //   note: "Budget from LMP / Charged based on actual work",
  //   status: "Waiting for Demand",
  // },
  {
    id: 5,
    projectMainName: "Monitoring",
    localDev: 4800000,
    projectCode: 960061,
    note: "",
    status: "",
  },
  {
    id: 6,
    projectMainName: "E-Invoice",
    localDev: 6800000,
    projectCode: 600141,
    note: "MY Cross Charge - Technology & Finance",
    status: "",
    dev: 35.5,
    qa: 0,
  },
  // {
  //   id: 7,
  //   projectMainName: "Standalone new member registration for partner referral 🇲🇾",
  //   localDev: 100000,
  //   projectCode: 61303,
  //   note: "MY Cross Charge - Loyalty",
  //   status: "",
  //   dev: 0.5,
  //   qa: 0
  // },
  // {
  //   id: 8,
  //   projectMainName: "Data Privacy 🇲🇾",
  //   localDev: 700000,
  //   projectCode: null,
  //   note: "MY Cross Charge - MY & TH Technology OPEX",
  //   status: "",
  //   dev: 2,
  //   qa: 0
  // },
  // {
  //   id: 9,
  //   projectMainName: "Customer Engagement via WhatsApp 🇲🇾",
  //   localDev: 600000,
  //   projectCode: null,
  //   note: "MY Cross Charge - Business Support",
  //   status: "",
  //   dev: 3,
  //   qa: 2
  // },
  // {
  //   id: 10,
  //   projectMainName: "GA Implementation 🇲🇾",
  //   localDev: 380000,
  //   projectCode: null,
  //   note: "MY Cross Charge - Technology OPEX",
  //   status: "",
  //   dev: 4,
  //   qa: 0
  // },
  // {
  //   id: 11,
  //   projectMainName: "Customer Registration Flow Changes 🇲🇾",
  //   localDev: 600000,
  //   projectCode: null,
  //   note: "MY Cross Charge - Loyalty",
  //   status: "",
  //   dev: 6.5,
  //   qa: 0
  // },
  {
    id: 12,
    projectMainName: "iHiring",
    localDev: 1050000,
    projectCode: 960067,
    note: "",
    status: "",
    dev: 12,
    qa: 0,
  },
  {
    id: 13,
    projectMainName: "WFM Rollout",
    localDev: 1200000,
    projectCode: 960065,
    note: "",
    status: "",
    dev: 8,
    qa: 0,
  },
  {
    id: 14,
    projectMainName: "Tenant Portal",
    localDev: 1800000,
    projectCode: 950281,
    note: "",
    status: "",
    dev: 10,
    qa: 0,
  },
  {
    id: 15,
    projectMainName: "F&A RPA 2024",
    localDev: 600000,
    projectCode: 960081,
    note: "",
    status: "",
    dev: 0,
    qa: 0,
  },
];

export default BUDGET_DATA;
