import React from "react";
import Seo from "../../components/seo";
import BudgetDetail from "../../components/Budget/details";
import queryString from "query-string";

const BudgetDetailPage = (props) => {
  const { search } = props.location;
  const { id } = queryString.parse(search);
  return (
    <>
      <Seo title="Budget Detail" />
      <BudgetDetail {...props} id={id} />
    </>
  );
};

export default BudgetDetailPage;
