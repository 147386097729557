import ReferralImg1 from "../images/referral-code/identity.png";
import ReferralImg2 from "../images/referral-code/mobile.png";
import ReferralTimeLine from "../images/referral-code/timeline.png";
import AccountRecoveryImg from "../images/account-recovery/estimations.png";
const PROJECT_LIST = [
  {
    projectId: "ENG-2023-01-001",
    icProjectCode: null,
    projectName: "BAU support",
    category: "Stabilization",
    domain: null,
    tpmPo: "Nakarin",
    sdm: "All SDMs",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 450,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "31/12/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "All squads",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-002",
    icProjectCode: null,
    projectName: "Supplier Portal",
    category: "Cost Saving",
    domain: "B&M",
    tpmPo: "Yaowaluck",
    sdm: "Mongkol",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 60,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "N",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Required new squad",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-003",
    icProjectCode: null,
    projectName: "New loyalty platform (Session M replacement)",
    category: "Cost Saving",
    domain: "CLM",
    tpmPo: "Patompong / Sirawit",
    sdm: "Mongkol, Art",
    stage: "Selecting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 378,
    qaManMonth: null,
    startDate: "1/4/2023",
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "N",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Required new squad (378 not include mobile app effort yet), plan to submit IC on 4/4/2023",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-004",
    icProjectCode: 960028,
    projectName: "Stock service optimization",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Art",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 45.5,
    qaManMonth: 7.5,
    startDate: "15/3/2023",
    finishDate: "31/3/2024",
    commitDeadline: "31/12/2023",
    releasedDate: "30/4/2024",
    estimatedGoLive: "30/6/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: 20000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Finished Stock Streaming, Implementing Stock API MY: No need to implement (Might adapt with Cloud Migration project)",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-005",
    icProjectCode: 960028,
    projectName: "Rebuild POS API",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 7.5,
    qaManMonth: 0,
    startDate: "1/3/2023",
    finishDate: "15/5/2023",
    commitDeadline: "31/12/2023",
    releasedDate: "4/3/2024",
    estimatedGoLive: "1/5/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-006",
    icProjectCode: 960028,
    projectName: "Rebuild CRM customer cache",
    category: "Stabilization",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 13,
    qaManMonth: 2,
    startDate: "15/2/2023",
    finishDate: "31/7/2024",
    commitDeadline: "31/12/2023",
    releasedDate: "15/3/2024",
    estimatedGoLive: "1/4/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "TH: Completed MY: Postpone to implement with Alibaba",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-007",
    icProjectCode: 960028,
    projectName: "Rebuild Customer API",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 44.5,
    qaManMonth: 25,
    startDate: "1/3/2023",
    finishDate: "31/3/2024",
    commitDeadline: "31/12/2023",
    releasedDate: "30/4/2024",
    estimatedGoLive: "1/5/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "TH: Completed Go-Live for CLM related. The remaining part is O2O. MY: CLM customer sync + O2O related",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-008",
    icProjectCode: 960028,
    projectName: "Rebuild SFMC API",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 7.5,
    qaManMonth: 9,
    startDate: "15/9/2023",
    finishDate: "31/12/2023",
    commitDeadline: "31/12/2023",
    releasedDate: "31/12/2023",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-009",
    icProjectCode: 960028,
    projectName: "Rebuild SFCRM API",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 7.5,
    qaManMonth: 0,
    startDate: "15/10/2023",
    finishDate: "31/12/2023",
    commitDeadline: "31/12/2023",
    releasedDate: "18/1/2024",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Completely Go-Live for CLM related. The remaining part is O2O related.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-010",
    icProjectCode: 960028,
    projectName: "Rebuild SessionM API",
    category: "Cost Saving",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 6,
    qaManMonth: 0,
    startDate: "1/1/2023",
    finishDate: "30/4/2023",
    commitDeadline: "31/12/2023",
    releasedDate: "30/4/2023",
    estimatedGoLive: "1/4/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Completely Go-Live for CLM related. The remaining part is O2O related.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-011",
    icProjectCode: null,
    projectName: "Mulesoft customer API optimization",
    category: "Stabilization",
    domain: "Engineering",
    tpmPo: "Napassakorn",
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Will include in BAU support",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-012",
    icProjectCode: null,
    projectName: "CMA 4.0 (DB migration)",
    category: "Cost Saving",
    domain: "Store",
    tpmPo: "TBD",
    sdm: "Sattha",
    stage: "Awaiting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-013",
    icProjectCode: null,
    projectName: "Stock service enhancement",
    category: "Cost Saving",
    domain: "Store",
    tpmPo: "Korawit",
    sdm: "Art, Sattha",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-014",
    icProjectCode: null,
    projectName: "POS realtime earn",
    category: "Stabilization",
    domain: "CLM",
    tpmPo: "Patompong / ?",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 4,
    qaManMonth: 8,
    startDate: "1/12/2022",
    finishDate: "1/4/2023",
    commitDeadline: "1/4/2023",
    releasedDate: "25/7/2023",
    estimatedGoLive: "1/4/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Deployed 2 pilot stores on July 5, 2023.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-015",
    icProjectCode: null,
    projectName: 'Partner point exchange (common partners) "OneSiam',
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / Tayavee",
    sdm: "Mongkol, Art, Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 17,
    qaManMonth: 9.5,
    startDate: "1/1/2023",
    finishDate: "15/4/2023",
    commitDeadline: "28/2/2023",
    releasedDate: "28/2/2023",
    estimatedGoLive: "6/3/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Treating new partner as BAU",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-016",
    icProjectCode: null,
    projectName: "Consent version management",
    category: "New",
    domain: "CLM",
    tpmPo: "TBC",
    sdm: "Mongkol, Art, Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Moved to 098",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-017",
    icProjectCode: 960027,
    projectName: "Non-Thai register",
    category: "New",
    domain: "CLM",
    tpmPo: "Apicha",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 27,
    qaManMonth: 16,
    startDate: "1/9/2023",
    finishDate: "15/4/2024",
    commitDeadline: null,
    releasedDate: "23/5/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 2100000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Carried over from last year. Effort in parentheses were effort spent from last year.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-018",
    icProjectCode: null,
    projectName: "LINE web loyalty",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 23,
    qaManMonth: null,
    startDate: "1/3/2023",
    finishDate: "31/3/2023",
    commitDeadline: "31/3/2023",
    releasedDate: "31/3/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Edit customer profile via Line",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-019",
    icProjectCode: null,
    projectName: "LMP - New Wallet",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / Apicha",
    sdm: "Mongkol, Art, Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 30,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "10/7/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "One App CLM = 2, Integration = 6, Customer - B-Team = 5, Customer - Loyalty = 5, PO = 12. Waiting to re-discuss with Kn. Vorawan, P.Cole, Kn. Paul",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-020",
    icProjectCode: null,
    projectName: "One app UX/UI improvement",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: "Mongkol, Art, Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "New scope so moved to new project 103",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-021",
    icProjectCode: null,
    projectName: "WFM (Scheduler)",
    category: "New",
    domain: "HR",
    tpmPo: "Tavikarn/Phuree",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 21.5,
    qaManMonth: 10,
    startDate: "1/1/2023",
    finishDate: "15/10/2023",
    commitDeadline: null,
    releasedDate: "31/8/2023",
    estimatedGoLive: "March 27, 2023 (Humanica Interface)",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-022",
    icProjectCode: null,
    projectName: "ร้านนี้ขายดี",
    category: "New",
    domain: null,
    tpmPo: "Atiporn Chongterdtoonskul",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 7.5,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Workflow = 7.5",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-023",
    icProjectCode: null,
    projectName: "Stock API x HLE",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Selecting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-024",
    icProjectCode: null,
    projectName: "Stock API x Dummy stock",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-025",
    icProjectCode: null,
    projectName: "Stock threshold by store",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-026",
    icProjectCode: 950281,
    projectName: "Tenant portal",
    category: "New",
    domain: "Store",
    tpmPo: "Patompong/Kanokrada",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 18,
    qaManMonth: 8.5,
    startDate: "1/3/2024",
    finishDate: "31/7/2024",
    commitDeadline: null,
    releasedDate: "31/6/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: 1800000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-027",
    icProjectCode: null,
    projectName: "B2B gift card",
    category: "New",
    domain: "Store",
    tpmPo: "Korawich/Duangjai",
    sdm: "Sattha, Atirat, Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "15/6/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "12/6/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Finished and release Phase 1",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-028",
    icProjectCode: null,
    projectName: "MSAYs 1.1 (Auditing)",
    category: "New",
    domain: "Store",
    tpmPo: "Korawich/Duangjai",
    sdm: "Sattha, Atirat, Mongkol",
    stage: "Awaiting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Biz request to continue even though R10 plan is unclear. Concern from the team around cost of restart once R10 plan is clear.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-029",
    icProjectCode: null,
    projectName: "MSAYs 2.0 (In App Paymeny)",
    category: "New",
    domain: "Store",
    tpmPo: "Sira",
    sdm: "Art",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-030",
    icProjectCode: null,
    projectName: "VAT refund website",
    category: "New",
    domain: "Store",
    tpmPo: "Sira",
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 12,
    qaManMonth: 4,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: "15/2/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Move to eVAT refund",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-031",
    icProjectCode: null,
    projectName: "E-Tax",
    category: "New",
    domain: "Store",
    tpmPo: "Phuree/Aunchulee",
    sdm: "Sattha",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "15/2/2023",
    commitDeadline: "15/2/2023",
    releasedDate: null,
    estimatedGoLive: "1/4/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-032",
    icProjectCode: null,
    projectName: "CMA 4.1 - new features",
    category: "New",
    domain: "Engineering",
    tpmPo: "Nakarin",
    sdm: "Nakarin",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 10.5,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: "31/5/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "*need TPM to present the plan to release it from On-hold",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-033",
    icProjectCode: null,
    projectName: "Build full CLM regression coverage",
    category: "Stabilization",
    domain: "CLM",
    tpmPo: "BAU",
    sdm: "Nakarin",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "31/5/2023",
    commitDeadline: null,
    releasedDate: "6/1/2023",
    estimatedGoLive: "Delivered some coverages. Moved to QA Automation Team",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Delivered some coverages. Moved to QA Automation Team",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-034",
    icProjectCode: null,
    projectName: "RPA Coupon/Campaign Creation Enhancement",
    category: "Stabilization",
    domain: "Store",
    tpmPo: "Duangjai",
    sdm: "Sattha",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 16.5,
    qaManMonth: 11,
    startDate: "1/10/2022",
    finishDate: "6/1/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive:
      "Delivered Robot script to business to run. Moved to BAU to support",
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Delivered Robot script to business to run. Moved to BAU to support",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-035",
    icProjectCode: 425009,
    projectName: "MSAYs MVP1",
    category: "Blossom",
    domain: "Property",
    tpmPo: "Aektanat Udomlumleart",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 10,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "31/8/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Mobile MSAYs = 6, PO = 1, UX/UI = 0.5 same as MSAY 1.1 status in Sattha's team.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-036",
    icProjectCode: 425009,
    projectName: "Stock API x Oracle SIM",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Art",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 5,
    qaManMonth: 0,
    startDate: "1/1/2023",
    finishDate: "15/3/2023",
    commitDeadline: null,
    releasedDate: "15/3/202",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Core API = 3 (only 3 man-month will be charged but remained one should be included in BAU)",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-037",
    icProjectCode: 425009,
    projectName: "FFTI Website",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Sira",
    sdm: "Art",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: 1,
    startDate: "1/11/2023",
    finishDate: "31/7/2024",
    commitDeadline: "16/10/2023",
    releasedDate: null,
    estimatedGoLive: "Treat as BAU - Deploy by Oct 9",
    addBookingSheet: null,
    originalBizCaseCost: 254000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Treat as BAU - Deploy by Oct 9",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-038",
    icProjectCode: 425009,
    projectName: "CMA 3.0",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Phuree/Aunchulee",
    sdm: "Sattha",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "15/8/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-039",
    icProjectCode: 425009,
    projectName: "Label 1.0",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Sattha/Sira",
    sdm: "Sattha",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "15/5/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-040",
    icProjectCode: 425009,
    projectName: "Store re-org update on Sharepoint",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Pakpoom Nawakitbumrung",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: 0,
    startDate: "1/1/2023",
    finishDate: "31/1/2023",
    commitDeadline: null,
    releasedDate: "31/1/2023",
    estimatedGoLive: "Feb, 2023",
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-041",
    icProjectCode: 425009,
    projectName: "Paperless for Oracle SIM",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Vichai",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 15.5,
    qaManMonth: 0,
    startDate: "1/2/2023",
    finishDate: "31/8/2023",
    commitDeadline: null,
    releasedDate: "31/8/2023",
    estimatedGoLive: "April, 2023",
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-042",
    icProjectCode: 425009,
    projectName: "Store & retails test automation",
    category: "Blossom",
    domain: "Store",
    tpmPo: "Duangjai",
    sdm: "Nakarin",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Need to discuss with P'Kae for more detail.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-043",
    icProjectCode: 960027,
    projectName: "Facebook offline conversion",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan",
    sdm: "Peerasak, Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: 0,
    startDate: "1/9/2023",
    finishDate: "15/10/2023",
    commitDeadline: null,
    releasedDate: "31/10/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 1400000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Vendor will do the testing",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-044",
    icProjectCode: null,
    projectName: "E2E campaign management",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: "TBD",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Move to SaaS development",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-045",
    icProjectCode: null,
    projectName: "DI report Project B2C VM",
    category: "New",
    domain: "Store",
    tpmPo: "Suriyapong",
    sdm: "Sattha",
    stage: "Gathering Requirement",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "To discuss with TPM to confirm current status. To check if need support from team or not.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-046",
    icProjectCode: null,
    projectName: "Career website ph.2",
    category: "New",
    domain: "HR",
    tpmPo: "Tavikarn",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Will include in BAU support",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-047",
    icProjectCode: null,
    projectName: "Customer profile lookup API (for data team)",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: 'Will combine with project "Rebuild CRM Cache',
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-048",
    icProjectCode: null,
    projectName: "My Lotus's run",
    category: "New",
    domain: "CLM",
    tpmPo: "Kanokrada / Sirawit",
    sdm: "Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "1/8/2023",
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Team provided initial estimate to Biz (1.6M approx).There is an additional requirement, TPM will discuss in more detail with Biz. To clarify project objective with Biz.  Biz confirmed to cancel this program",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-049",
    icProjectCode: null,
    projectName: "Mall loyalty program",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / ?",
    sdm: "TBD",
    stage: "Providing Estimation",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "1/9/2023",
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Team working on project estimation.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-050",
    icProjectCode: null,
    projectName: "CDP replacement",
    category: "Cost Saving",
    domain: "CLM",
    tpmPo: "Gavin / ?",
    sdm: "TBD",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-051",
    icProjectCode: null,
    projectName: "CP Group - OneID / Amaze ID",
    category: "New",
    domain: "CLM",
    tpmPo: "Srisuk / ?",
    sdm: "TBD",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-052",
    icProjectCode: null,
    projectName: "CP Group - 5 Prasarn",
    category: "New",
    domain: "CLM",
    tpmPo: "TBC / TBC",
    sdm: "TBD",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Combined with One ID",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-053",
    icProjectCode: null,
    projectName: "Scandit",
    category: "New",
    domain: "Store",
    tpmPo: "Atiporn",
    sdm: "Sattha",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Store - CMA = 2. Require Engineer team to discuss with BD team. Find biz owner/stakeholder.  17/03/23 - P'Pat will discuss with k.Paul",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-054",
    icProjectCode: null,
    projectName: "LMP Lotus' consent API and weekly batch (customer sync)",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Apicha",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 5.5,
    qaManMonth: 1,
    startDate: "15/1/2023",
    finishDate: "15/3/2023",
    commitDeadline: null,
    releasedDate: "15/3/2023",
    estimatedGoLive: "25/4/2023",
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "UAT - Done Plan to deploy 28/03/2023, Move to 25/04/2023 due to contract delay with LMP Review contract with LMP. Will split IC into 2 and proceed on this work on 9 May",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-055",
    icProjectCode: null,
    projectName: "New facility management (replace Verise)",
    category: "New",
    domain: "Property",
    tpmPo: "Chawaphon",
    sdm: "Peerasak",
    stage: "Selecting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 45,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Already provided options and estimation to user. There are software packages and vendor as an option.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-056",
    icProjectCode: 425009,
    projectName: "Cash Office Application",
    category: "Blossom",
    domain: "Finance",
    tpmPo: "Atcharapan",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 9,
    qaManMonth: 0,
    startDate: "1/5/2023",
    finishDate: "15/9/2023",
    commitDeadline: null,
    releasedDate: "15/9/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Transferred knowledge from mandix. BAU -> for KT",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-057",
    icProjectCode: null,
    projectName: "Stock for Amaze",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Selecting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-058",
    icProjectCode: 425009,
    projectName: "Stock & Product streaming for Marketplace",
    category: "Blossom",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 4.5,
    qaManMonth: 3,
    startDate: "15/1/2023",
    finishDate: "28/2/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: 254000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Dev finished, waiting to deploy",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-059",
    icProjectCode: null,
    projectName: "Stock API x Product Range",
    category: "New",
    domain: "O2O",
    tpmPo: "Art",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1.5,
    qaManMonth: 1,
    startDate: "1/1/2023",
    finishDate: "22/1/2023",
    commitDeadline: null,
    releasedDate: "22/1/2023",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Core API = 2.5 (Still need to enhance to use SRD [71])",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-060",
    icProjectCode: null,
    projectName: "Unmanned Store (Checkout)",
    category: "New",
    domain: "Store",
    tpmPo: "Wiphak",
    sdm: "Sattha",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: 0,
    startDate: "15/1/2023",
    finishDate: "15/4/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Store - Label = 1",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-061",
    icProjectCode: null,
    projectName: "RMS x PGRX",
    category: "New",
    domain: "B&M",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: 5,
    startDate: "15/4/2023",
    finishDate: "31/5/2023",
    commitDeadline: null,
    releasedDate: "9/9/2023",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Core API = 3 / Blossom budget",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-062",
    icProjectCode: null,
    projectName: "Partner point portal",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "BAU สำหรับฝั่งdev แต่ถ้าแก้ให้ Biz จะให้ไปทำ CRมา",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-063",
    icProjectCode: null,
    projectName: "EIM Enhancement",
    category: "New",
    domain: "B&M",
    tpmPo: "TBD",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: null,
    startDate: "1/1/2023",
    finishDate: "31/12/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "มีCR มาเรื่อยๆๆ ยังไม่จบ พี่แพทต้องไปคุยกับพี่เกียร กับพี่เล็ก ให้รวมมาเป็น project",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-01-064",
    icProjectCode: null,
    projectName: "Order Review Enhancement",
    category: "New",
    domain: "Fulfillment",
    tpmPo: "TBD",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1,
    qaManMonth: null,
    startDate: "1/2/2023",
    finishDate: "31/12/2023",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "มีCR มาเรื่อยๆๆ ยังไม่จบ พี่แพทต้องไปคุยกับพี่เกียร กับพี่เล็ก ให้รวมมาเป็น project",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-065",
    icProjectCode: null,
    projectName: "Smart Shopping Cart",
    category: "New",
    domain: "New Biz",
    tpmPo: "TBD",
    sdm: "Peerasak",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "P'Pat will discuss with Dr.Viriya and BD team.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-066",
    icProjectCode: null,
    projectName: "Indoor Navigation",
    category: "New",
    domain: "New Biz",
    tpmPo: "TBD",
    sdm: "Peerasak",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "P'Pat will discuss with Dr.Viriya and BD team.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-067",
    icProjectCode: null,
    projectName: "Lotus's Tag",
    category: "New",
    domain: "Engineering",
    tpmPo: "Siripong Kongchouy",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1,
    qaManMonth: 0,
    startDate: "15/2/2023",
    finishDate: "15/3/2023",
    commitDeadline: null,
    releasedDate: "15/3/2023",
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-068",
    icProjectCode: null,
    projectName: "Reconciliation Report",
    category: "Stabilization",
    domain: "Store",
    tpmPo: "Adisorn J",
    sdm: "Nakarin",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: 0,
    startDate: "1/3/2023",
    finishDate: "30/6/2023",
    commitDeadline: null,
    releasedDate: "30/6/2023",
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-069",
    icProjectCode: null,
    projectName: "eVat Refund",
    category: "New",
    domain: "Store",
    tpmPo: "Sira",
    sdm: "Art",
    stage: "Providing Estimation",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Still in Gate 1",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-070",
    icProjectCode: null,
    projectName: "FF Split ordering",
    category: "New",
    domain: "Fulfillment",
    tpmPo: "สายชล",
    sdm: "Mongkol",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-071",
    icProjectCode: null,
    projectName: "Stock API x SRD",
    category: "New",
    domain: "O2O",
    tpmPo: "Tob",
    sdm: "Art",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Moved and combined with 104",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-072",
    icProjectCode: null,
    projectName: "New Virtual Store for Talad",
    category: "New",
    domain: "Store",
    tpmPo: "Tob",
    sdm: "Satha",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-073",
    icProjectCode: null,
    projectName: "HLE Store",
    category: "New",
    domain: "B&M",
    tpmPo: "Tob, Kittisak, Chanon (Gorge)",
    sdm: "Peerasak",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-074",
    icProjectCode: null,
    projectName: "Stock for Flash sales",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Not Ready to Add",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-075",
    icProjectCode: null,
    projectName: "E-Tax O2O - Re-Issue for change Customer Info",
    category: "New",
    domain: "O2O",
    tpmPo: "TBD",
    sdm: "Art",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "E Tax for marketplace (Demand Gate 1)",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-076",
    icProjectCode: null,
    projectName: "Amaze Point Earn at POS",
    category: "New",
    domain: "O2O",
    tpmPo: "Patompong/Sirawit",
    sdm: "Art, Duangjai",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-02-077",
    icProjectCode: null,
    projectName:
      "TMN x MyLo Campaign - Coin X2 when pay with TMN at Lotus stores (offline + online)",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa",
    sdm: "Mongkol",
    stage: "Awaiting Solution",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "End of May 2023",
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Waiting for new PO + Solutions",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-078",
    icProjectCode: null,
    projectName: "Online memo approval portal",
    category: "New",
    domain: "Finance",
    tpmPo: "Wanlaya",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: "Y",
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Provided estimation to biz team.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-079",
    icProjectCode: null,
    projectName: "TMN x ID binding (from Registration)",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 8.5,
    qaManMonth: 5.5,
    startDate: "1/7/2023",
    finishDate: "31/8/2023",
    commitDeadline: null,
    releasedDate: "28/9/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Backend= done / Frontend= implementing",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-080",
    icProjectCode: 960027,
    projectName:
      "Coin Earn / Burn reason and/or source on Notification and Coin history [2nd]",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Jomkwan",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 5,
    qaManMonth: 5,
    startDate: "15/5/2023",
    finishDate: "30/9/2023",
    commitDeadline: "31/10/2023",
    releasedDate: "22/6/2023",
    estimatedGoLive: "20/6/2023",
    addBookingSheet: null,
    originalBizCaseCost: 1100000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "IC 9 May 2023, June 22 2023 - First release for Biz campaign",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-081",
    icProjectCode: null,
    projectName: "TMN - Coin convert / transfer to TMN",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / Tayavee",
    sdm: "Mongkol, Art",
    stage: "Initiated",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Will resume in 2024",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-082",
    icProjectCode: null,
    projectName: "Revamp registration page (per UX/UI recommendation) [2nd]",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Jomkwan",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 18.5,
    qaManMonth: 8,
    startDate: "1/4/2023",
    finishDate: "15/6/2023",
    commitDeadline: null,
    releasedDate: "22/6/2023",
    estimatedGoLive: "20/6/2023",
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "IC 9 May 2023, June 22 2023 - First release for Biz campaign",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-083",
    icProjectCode: null,
    projectName: "Reconsent collection on Mobile App [2nd], Web, LINE",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Jomkwan",
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-084",
    icProjectCode: null,
    projectName: "BZB (3rd Party Deals) replacement (MY)",
    category: "New",
    domain: "CLM",
    tpmPo: "KK / ?",
    sdm: "Mongkol, Art, Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-085",
    icProjectCode: null,
    projectName: "E-Stamp / Collectables (MY)",
    category: "New",
    domain: "CLM",
    tpmPo: "KK / ?",
    sdm: "Mongkol, Art, Atirat",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-086",
    icProjectCode: 960027,
    projectName:
      "Revamp My coupon : MyLo / coupon / voucher pages (per UX/UI recommendation) [3rd]",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Jomkwan",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 25,
    qaManMonth: 9.5,
    startDate: "15/5/2023",
    finishDate: "30/9/2023",
    commitDeadline: "10/10/2023",
    releasedDate: "16/1/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 5000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "IC 9 May 2023, Scope to include both Mobile App and LINE (having Coupon / Voucher List + Detail Page)",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-087",
    icProjectCode: null,
    projectName:
      "TMN x MyLo Campaign - Extra coin X2 when register MyLo at TMN app",
    category: "New",
    domain: "CLM",
    tpmPo: "Wanwisa / ?",
    sdm: null,
    stage: "Gathering Requirement",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Waiting for new PO + Solutions",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-088",
    icProjectCode: 425009,
    projectName: "R10 Integration Redesign",
    category: "Blossom",
    domain: "CLM",
    tpmPo: "Patompong / Kanokrada / ?",
    sdm: "Art, Sattha",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 45,
    qaManMonth: 17,
    startDate: "1/8/2023",
    finishDate: "30/4/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: "End of Sept 2023",
    addBookingSheet: null,
    originalBizCaseCost: 254000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Man-month are not included from Jong's team",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-089",
    icProjectCode: null,
    projectName: "Stock API x Smart Soft",
    category: "New",
    domain: "New Biz",
    tpmPo: "Patchanee Sutadarat (Nok)",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "As BAU",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-090",
    icProjectCode: null,
    projectName: "SSR CRs",
    category: "New",
    domain: null,
    tpmPo: "noppadon sathongnoan",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-091",
    icProjectCode: null,
    projectName: "ServiceDesk Migration from Outsystems",
    category: "New",
    domain: "Store",
    tpmPo: "Pakpoom",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-092",
    icProjectCode: 960027,
    projectName: "LINE Premium Redemption (Line & App)",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasnan / Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 11,
    qaManMonth: 8.5,
    startDate: "1/4/2023",
    finishDate: "22/6/2023",
    commitDeadline: null,
    releasedDate: "22/6/2023",
    estimatedGoLive: "22/6/2023",
    addBookingSheet: null,
    originalBizCaseCost: 2100000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "IC 9 May 2023, June 22 2023 - First release for Biz campaign",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-093",
    icProjectCode: null,
    projectName: "Fusionex SDK implementation",
    category: "New",
    domain: "CLM",
    tpmPo: "Pei Lin",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: null,
    startDate: "15/6/2023",
    finishDate: "15/7/2023",
    commitDeadline: "15/7/2023",
    releasedDate: "15/8/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Dev finished on time but there was a problem on SDK side which delay deployment",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-094",
    icProjectCode: null,
    projectName: "Mall directory / campaign / promotion on App",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Up to Mall team",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-095",
    icProjectCode: 960027,
    projectName: "Dedicated promotion page in App",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanasanan / Sirawit",
    sdm: "Atirat",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 600000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Business priority changed. Removed from booking sheet. Confirmed with P'Suk on Sep 14, 2023",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-096",
    icProjectCode: 960027,
    projectName: "Edit Mobile number in App, Web, LINE",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: "Mongkol, Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 21.5,
    qaManMonth: 13.5,
    startDate: "1/10/2023",
    finishDate: "15/1/2024",
    commitDeadline: "31/1/2024",
    releasedDate: "26/3/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 700000000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Carried over from last year. Effort in parentheses were effort spent from last year.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-097",
    icProjectCode: null,
    projectName: "Social Login in App / Web",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Leave with O2O",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-098",
    icProjectCode: 960027,
    projectName: "Marketing commuincation consent on Mobile App, Web, LINE",
    category: "New",
    domain: "CLM",
    tpmPo: "Apicha",
    sdm: "Mongkol, Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 13,
    qaManMonth: 5.5,
    startDate: "1/1/2024",
    finishDate: "28/2/2024",
    commitDeadline: null,
    releasedDate: "24/4/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 700000000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Carried over from last year. Effort in parentheses were effort spent from last year.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-099",
    icProjectCode: 960027,
    projectName:
      "My coupon (LINE) : MyLo / coupon / voucher pages for other coupons than premium",
    category: "New",
    domain: "CLM",
    tpmPo: "Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 8.5,
    qaManMonth: 5,
    startDate: "15/6/2023",
    finishDate: "15/9/2023",
    commitDeadline: null,
    releasedDate: "17/1/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 3400000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-100",
    icProjectCode: null,
    projectName:
      "My coupon (App) : Improve UX/UI flow for searchign and using coupons in App (added)",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Confirmed with Biz. Same as ENG-2023-03-086",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-03-101",
    icProjectCode: 960027,
    projectName: "Filling in registration info using camera scan",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: "Mongkol, Atirat",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 1000000,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "Business priority changed. Removed from booking sheet. Confirmed with P'Suk on Sep 14, 2023",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-06-102",
    icProjectCode: null,
    projectName: "Price Checking Tool",
    category: "New",
    domain: "CLM",
    tpmPo: "Jeranan",
    sdm: "Parama",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 5.5,
    qaManMonth: null,
    startDate: "1/6/2023",
    finishDate: "31/8/2023",
    commitDeadline: null,
    releasedDate: "27/7/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM083 - Currently on hold. Released P1.1 Still adjusting script to support changes from Big C",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-07-103",
    icProjectCode: 960027,
    projectName: "UXUI improvement (Personalized, notification)",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: "Mongkol, Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 34.5,
    qaManMonth: 6,
    startDate: "15/9/2023",
    finishDate: "15/2/2024",
    commitDeadline: null,
    releasedDate: "27/3/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 3000000,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "Carried over from last year. Effort in parentheses were effort spent from last year.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-07-104",
    icProjectCode: null,
    projectName: "Integrate Planogram Product Ranges to Stock Service​",
    category: "Stabilization",
    domain: "O2O",
    tpmPo: "Paveesuda",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 4,
    qaManMonth: 2,
    startDate: "1/9/2023",
    finishDate: "31/10/2023",
    commitDeadline: "31/10/2023",
    releasedDate: "31/10/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM099 https://thlotuss.sharepoint.com/:p:/r/teams/TechProgramandServiceManagementOffice/_layouts/15/Doc.aspx?sourcedoc=%7B5C248F1E-7D21-4B0B-A828-32DA6655DF22%7D&file=(099)1Demand_O2O_Clubpack%20merge_Paveesuda%20Chaijiroj.pptx&action=edit&mobileredirect=true",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-08-105",
    icProjectCode: null,
    projectName: "Store Specific Range & Store Specific Planogram​",
    category: "New",
    domain: "B&M",
    tpmPo: "Nuntiya",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1,
    qaManMonth: null,
    startDate: "15/8/2023",
    finishDate: "15/9/2023",
    commitDeadline: null,
    releasedDate: "6/9/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM114 https://thlotuss.sharepoint.com/:p:/r/teams/TechProgramandServiceManagementOffice/_layouts/15/Doc.aspx?sourcedoc=%7B91DD7E24-EE9D-48C5-A4C5-C34CC1201B0B%7D&file=(114)Demand_Store%20Specific%20Range_Yaowaluck%20Peyaprapapun.pptx&action=edit&mobileredirect=true",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-08-106",
    icProjectCode: null,
    projectName: "Vendor Booking System",
    category: "New",
    domain: "Fulfillment",
    tpmPo: "Wasuthep",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 3.5,
    qaManMonth: null,
    startDate: "15/8/2023",
    finishDate: "1/1/2024",
    commitDeadline: null,
    releasedDate: "1/2/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023", "2024"],
    image: null,
    tags: null,
    note: "DM128 + DM129",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-10-107",
    icProjectCode: null,
    projectName: "Infra Asset Management",
    category: "New",
    domain: null,
    tpmPo: "Gorrakot",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 4.5,
    qaManMonth: null,
    startDate: "1/11/2023",
    finishDate: "15/12/2023",
    commitDeadline: null,
    releasedDate: "15/12/2023",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM-157 - https://lotusretails.atlassian.net/browse/DEM-157",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-10-108",
    icProjectCode: null,
    projectName: "Social Login",
    category: "New",
    domain: "O2O",
    tpmPo: "Anoot",
    sdm: "Mongkol",
    stage: "Ready to Develop",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM-144",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-11-109",
    icProjectCode: null,
    projectName: "E-Tax Marketplace",
    category: "New",
    domain: "O2O",
    tpmPo: "Anoot",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2,
    qaManMonth: null,
    startDate: "1/11/2023",
    finishDate: "1/12/2023",
    commitDeadline: null,
    releasedDate: "9/1/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: "DM-093",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-11-110",
    icProjectCode: 960027,
    projectName: "Coin Scheme",
    category: "New",
    domain: "CLM",
    tpmPo: "Vanassanan",
    sdm: "Art, Mongkol, Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 5.5,
    qaManMonth: 6.5,
    startDate: "1/11/2023",
    finishDate: "31/12/2023",
    commitDeadline: null,
    releasedDate: "16/1/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2023"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-111",
    icProjectCode: 960064,
    projectName: "iHiring",
    category: "New",
    domain: "HR",
    tpmPo: "Jeeranan / Tavigarn",
    sdm: "Peerasak",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 14.5,
    qaManMonth: null,
    startDate: "15/1/2024",
    finishDate: "15/5/2024",
    commitDeadline: null,
    releasedDate: "20/6/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 1050000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-155",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-112",
    icProjectCode: 960065,
    projectName: "WFM Rollout",
    category: "New",
    domain: "HR",
    tpmPo: "Jeeranan / Tavigarn",
    sdm: "Peerasak",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 8,
    qaManMonth: null,
    startDate: "1/2/2024",
    finishDate: "31/10/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 1200000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-152",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-113",
    icProjectCode: null,
    projectName: "Bidding Workflow",
    category: "New",
    domain: null,
    tpmPo: "Thanyanut",
    sdm: "Peerasak",
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 2000000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-115",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-114",
    icProjectCode: 960061,
    projectName: "System Monitoring",
    category: "New",
    domain: null,
    tpmPo: "Kraithep",
    sdm: "All SDMs",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 21,
    qaManMonth: 0,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-177",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-115",
    icProjectCode: 600141,
    projectName: "E-Invoice - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Eric Wong",
    sdm: "Art",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 48.5,
    qaManMonth: 0,
    startDate: "15/3/2024",
    finishDate: "31/12/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 6800000000000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-166",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-116",
    icProjectCode: null,
    projectName: "Customer Registration Flow Changes - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Pei Lin",
    sdm: "Art, Atirat, Mongkol",
    stage: "On-hold",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 0,
    qaManMonth: null,
    startDate: "1/6/2024",
    finishDate: "30/6/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-183",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-117",
    icProjectCode: null,
    projectName: "GA Implementation - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Pei Lin",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 4,
    qaManMonth: 0,
    startDate: "15/4/2024",
    finishDate: "31/5/2024",
    commitDeadline: null,
    releasedDate: "6/6/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-165",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-118",
    icProjectCode: null,
    projectName: "Data Privacy - Retention Policy (Customer Data) - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Pei Lin",
    sdm: "Art",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 2,
    qaManMonth: 1.5,
    startDate: "15/5/2024",
    finishDate: "15/6/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-169",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2023-12-119",
    icProjectCode: 960049,
    projectName: "SSPOG",
    category: "New",
    domain: null,
    tpmPo: "Nuntiya",
    sdm: "Mongkol, Sattha, Parama",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 13.5,
    qaManMonth: 1.5,
    startDate: "15/4/2024",
    finishDate: "30/9/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-164",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-120",
    icProjectCode: 840026,
    projectName: "Amaze ID integration and Point Exchange",
    category: "New",
    domain: "CLM",
    tpmPo: "Nahathai / Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 12,
    qaManMonth: 9,
    startDate: "1/2/2024",
    finishDate: "31/3/2024",
    commitDeadline: null,
    releasedDate: "30/5/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-121",
    icProjectCode: null,
    projectName: "Lotus's Eat",
    category: "New",
    domain: "O2O",
    tpmPo: "Aektanat",
    sdm: "Parama",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 1,
    qaManMonth: 1,
    startDate: "15/1/2024",
    finishDate: "15/2/2024",
    commitDeadline: null,
    releasedDate: "14/6/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-133",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-122",
    icProjectCode: 960075,
    projectName: "App Enhancement (UXUI)",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: "Atirat",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 15,
    qaManMonth: 6.5,
    startDate: "15/1/2024",
    finishDate: "31/12/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "UXUI Enhancement - SDT Sheet",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-123",
    icProjectCode: 960075,
    projectName: "Referral Code",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: "Atirat",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 22,
    qaManMonth: 7,
    startDate: "15/1/2024",
    finishDate: "15/5/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 3600000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Sales uplift 518.4MB",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-124",
    icProjectCode: 960075,
    projectName: "3 Prasarn Program",
    category: "New",
    domain: "CLM",
    tpmPo: "Tayavee",
    sdm: "Atirat, Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 9.5,
    qaManMonth: 6.5,
    startDate: "1/3/2024",
    finishDate: "31/5/2024",
    commitDeadline: null,
    releasedDate: "4/6/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-125",
    icProjectCode: 960075,
    projectName: "Reward Store - Revise Coupon Mechanic",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: null,
    stage: "Gathering Requirement",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-126",
    icProjectCode: null,
    projectName:
      "SessionM  - Improve capability to be able to blast coupons to more than 2M target",
    category: "New",
    domain: "CLM",
    tpmPo: null,
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "SessionM will handle. Improve capability to be able to blast coupons to more than 2M target audiences per campaign.",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-127",
    icProjectCode: null,
    projectName: "Customer Tier / Subscribption",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit/Patompong",
    sdm: null,
    stage: "Cancelled",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: null,
    qaManMonth: null,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Same with project 138",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-01-128",
    icProjectCode: 960075,
    projectName: "Speed up coin notification",
    category: "New",
    domain: "CLM",
    tpmPo: "Patompong",
    sdm: "Art",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 2,
    qaManMonth: 0.5,
    startDate: "15/1/2024",
    finishDate: "15/2/2024",
    commitDeadline: null,
    releasedDate: "26/3/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Request from K. Wan",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-02-129",
    icProjectCode: null,
    projectName:
      "Standalone Member Registration Page for Partner Referrals - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Kam Kah Kiang",
    sdm: "Atirat",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 0.5,
    qaManMonth: null,
    startDate: "15/2/2024",
    finishDate: "29/2/2024",
    commitDeadline: null,
    releasedDate: "27/3/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 100000000000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-192",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-02-130",
    icProjectCode: null,
    projectName: "Customer Engagement via WhatApp - Malaysia",
    category: "New",
    domain: "CLM",
    tpmPo: "Pei Lin",
    sdm: "Art, Atirat",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 3,
    qaManMonth: 4,
    startDate: "15/4/2024",
    finishDate: "15/7/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "DM-143",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-03-131",
    icProjectCode: 960075,
    projectName: "Account Recovery",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit / Apicha",
    sdm: "Art, Atirat, Mongkol",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 9.5,
    qaManMonth: 2,
    startDate: "15/5/2024",
    finishDate: "30/6/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "SDT",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-03-132",
    icProjectCode: 960075,
    projectName: "Mission - Shopping",
    category: "New",
    domain: "CLM",
    tpmPo: "Varin / Patompong",
    sdm: "Art, Atirat, Mongkol",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 11,
    qaManMonth: 9.5,
    startDate: "1/4/2024",
    finishDate: "15/6/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "SDT",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-03-133",
    icProjectCode: null,
    projectName: "Order Reivew CMA",
    category: "New",
    domain: "Fulfillment",
    tpmPo: "Gavin",
    sdm: "Mongkol",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 2.5,
    qaManMonth: 0,
    startDate: "15/3/2024",
    finishDate: "15/4/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "SDT - DEM-207",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-03-134",
    icProjectCode: 960075,
    projectName: "LINE Point History",
    category: "New",
    domain: "CLM",
    tpmPo: "Tayavee",
    sdm: "Mongkol",
    stage: "Completed",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 2,
    qaManMonth: 1,
    startDate: "15/3/2024",
    finishDate: "15/4/2024",
    commitDeadline: null,
    releasedDate: "5/6/2024",
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Additonal Request, not in SDT",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-04-135",
    icProjectCode: 960075,
    projectName: "Mall Loyalty",
    category: "New",
    domain: "CLM",
    tpmPo: "Varin / Tayavee",
    sdm: "Art, Mongkol",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: "7f7d81b4-4438-45dd-9ad6-9a22bf5fd2ac",
    devManMonth: 7.5,
    qaManMonth: 2.5,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Waiting for Project Code",
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-136",
    icProjectCode: 960075,
    projectName: "Point Exchange",
    category: "New",
    domain: "CLM",
    tpmPo: "Tayavee",
    sdm: "Art, Mongkol",
    stage: "Implementing",
    country: ["Thailand"],
    detail: null,
    budgetId: null,
    devManMonth: 3,
    qaManMonth: 1,
    startDate: "1/5/2024",
    finishDate: "15/5/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: 200000,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "SDT",
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-137",
    icProjectCode: 960075,
    projectName: "Shop Marathon",
    category: "New",
    domain: "CLM",
    tpmPo: "Varin / Tayavee",
    sdm: "Art, Atirat",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 4,
    qaManMonth: 4,
    startDate: "1/6/2024",
    finishDate: "15/6/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Not in SDT",
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-138",
    icProjectCode: 960075,
    projectName: "Paid Subscription",
    category: "New",
    domain: "CLM",
    tpmPo: "Varin / Patompong",
    sdm: null,
    stage: "Ready to Develop",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 0,
    qaManMonth: 0,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: "Not in SDT",
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-139",
    icProjectCode: null,
    projectName: "Harmony - FFTI",
    category: "New",
    domain: "Store",
    tpmPo: "Sira",
    sdm: "Art",
    stage: "Ready to Develop",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 6,
    qaManMonth: 1.5,
    startDate: "1/5/2024",
    finishDate: "31/7/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-140",
    icProjectCode: 960075,
    projectName: "Flex Queue Replacement",
    category: "New",
    domain: "CLM",
    tpmPo: "Sirawit",
    sdm: "Art",
    stage: "Implementing",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 13.5,
    qaManMonth: 3.5,
    startDate: "1/5/2024",
    finishDate: "31/8/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-141",
    icProjectCode: 960075,
    projectName: "BZB Offer on LINE Web Loyalty",
    category: "New",
    domain: "CLM",
    tpmPo: "Tayavee",
    sdm: "Mongkol",
    stage: "Ready to Develop",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 9,
    qaManMonth: 4.5,
    startDate: "15/6/2024",
    finishDate: "31/8/2024",
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
  {
    projectId: "ENG-2024-05-142",
    icProjectCode: null,
    projectName: "Harmony - Store",
    category: "New",
    domain: "Store",
    tpmPo: null,
    sdm: "Sattha",
    stage: "Ready to Develop",
    country: null,
    detail: null,
    budgetId: null,
    devManMonth: 0,
    qaManMonth: 0,
    startDate: null,
    finishDate: null,
    commitDeadline: null,
    releasedDate: null,
    estimatedGoLive: null,
    addBookingSheet: null,
    originalBizCaseCost: null,
    projectYear: ["2024"],
    image: null,
    tags: null,
    note: null,
    createdBy: "Parama",
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    deletedBy: null,
    deletedDate: null,
  },
];

export default PROJECT_LIST;
