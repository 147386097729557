import React, { useEffect, useState } from "react";
import {
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import PROJECT_LIST from "../../constants/projectList";
import BUDGET_DATA from "../../constants/budget";
import numberWithCommas from "../../utils/numberWithCommas";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "gatsby";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { sum } from "lodash";
import COLOR from "../../constants/colorList";
ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles({
  tableRowStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "darkGrey",
    },
  },
});

function BudgetDetail(props) {
  const classes = useStyles();
  const { id } = props;
  const budgetData = BUDGET_DATA.filter((i) => parseInt(i.id) === parseInt(id));
  const [data, setData] = useState([]);

  const CLMBudgetList = PROJECT_LIST.filter(
    (i) =>
      i.domain === "CLM" &&
      i.projectYear?.includes("2024") &&
      !i.name?.includes("- MY") &&
      !i.name?.includes("R10") &&
      i.stage !== "Cancelled"
  )
    .map(
      (d) =>
        parseFloat(d["devManMonth"]) * 100000 +
        parseFloat(d["qaManMonth"]) * 100000
    )
    .filter((a) => !Number.isNaN(a));

  const chartData = {
    labels: ["Spending", "Total"],
    datasets: [
      {
        data: [sum(CLMBudgetList), budgetData[0]?.localDev],
        backgroundColor: COLOR,
      },
    ],
  };

  const options = {
    rotation: 270,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  useEffect(() => {
    if (id === "1") {
      setData(
        PROJECT_LIST.filter(
          (i) =>
            i.domain === "CLM" &&
            i.projectYear?.includes("2024") &&
            !i.name?.includes("- Malaysia") &&
            !i.name?.includes("R10") &&
            i.stage !== "Cancelled"
        )
      );
    }
  }, []);

  return (
    <Container className="container">
      <div className="page-header">
        <h2>Budget Detail for {budgetData && budgetData[0]?.project}</h2>
      </div>

      <div className="project-detail">
        <div className="project-list-wrapper">
          {/* <div className="widget flex">
              <div className="summary-item total">
                Local Dev Spending
                <h5>{numberWithCommas(sum(CLMBudgetList))}</h5>
              </div>
              <div className="summary-item inprogress">
                Total Budget
                <h5>
                  {budgetData
                    ? numberWithCommas(budgetData[0]?.localDev || 0)
                    : "N/A"}
                </h5>
              </div>
              <div className="summary-item chart">
                <p className="percentage">
                  {budgetData
                    ? (
                        (sum(CLMBudgetList) * 100) /
                        budgetData[0]?.localDev
                      ).toFixed(0)
                    : "N/A"}
                  %
                </p>
                <Doughnut data={chartData} options={options} />
              </div>
            </div> */}
          <div className="table-wrapper">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Project</b>
                  </TableCell>
                  <TableCell>
                    <b>DEV Man-Month</b>
                  </TableCell>
                  <TableCell>
                    <b>QA Man-Month</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Project Cost (THB)</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((data, index) => {
                  return (
                    <TableRow
                      className={classes.tableRowStyle}
                      key={index}
                      onClick={() =>
                        navigate(`/project-details?id=${data?.id}`)
                      }
                    >
                      <TableCell>{`[${data["projectId"].slice(-3)}] ${
                        data.name
                      }`}</TableCell>
                      <TableCell>
                        {data["devManMonth"] || "N/A"}{" "}
                        {data.dev && `(${data.dev}) *`}
                      </TableCell>
                      <TableCell>
                        {data["qaManMonth"] || "N/A"}{" "}
                        {data.qa && `(${data.qa}) *`}
                      </TableCell>
                      <TableCell>
                        {numberWithCommas(
                          (parseFloat(data["devManMonth"]) * 100000 || 0) +
                            parseFloat(data["qaManMonth"] * 100000 || 0)
                        )}
                      </TableCell>
                      <TableCell>{data.stage}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <p className="remark">
              * The effort expended last year is not factored into this year's
              budget calculations.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default BudgetDetail;
